import { MdAddIcCall, MdMessage, MdEmail, MdError } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";
import "./ProfileSection.css";

// eslint-disable-next-line react/prop-types
const ProfileSection = ({ introDetails, contactName, mediaDetails }) => {
  // eslint-disable-next-line react/prop-types
  let { introHeading, imageUrl } = introDetails;

  const SOCIAL_ICONS = {
    MdAddIcCall: <MdAddIcCall />,
    MdMessage: <MdMessage />,
    MdEmail: <MdEmail />,
    MdError: <MdError />,
    IoCall: <IoCall />,
    RiWhatsappFill: <RiWhatsappFill />,
  };

  return (
    <div className="head__section">
      <img src={imageUrl} alt="profile" />
      <p>{introHeading}</p>
      <p>
        Hi, I am <span className="bold-text">{contactName}</span> from{" "}
        <span className="bold-text">Decentro</span>
      </p>

      {mediaDetails.length !== 0 && (
        <div className="media-details">
          {mediaDetails.map((detail) => {
            return (
              <div className="media">
                <a key={detail.iconText} href={detail.sourceLink}>
                  <div className="media-tap">
                    {SOCIAL_ICONS[detail.iconText || "MdError"]}
                  </div>
                </a>
                {detail?.subText && (
                  <span className="icon-sub-text">{detail.subText}</span>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProfileSection;
